import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FiInfo } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CyberTable from "../components/Tables/CyberTable";
import PaymentGatewayTable from "../components/Tables/PaymentGatewayTable";
import MerchantTable from "../components/MerchantTable";
import BankTable from "../components/Tables/BankTable";
import { GetAllOrganization } from "../controller/organization";
import DashboardHeader from "../components/DashboardHeader";

function AdminOrgManagement() {
  const [tabs, setTabs] = useState(0);

  const authToken = localStorage.getItem("authToken");
  const navigate = useNavigate();

  if (authToken) {
    try {
      const authData = JSON.parse(authToken);
      var token = authData.token;
      var userId = authData.user_id;
    } catch (e) {
      console.error("Error parsing authToken:", e);
    }
  } else {
    console.error("authToken not found in localStorage");
  }
  const [allOrganization, setAllOrganization] = useState();

  useEffect(() => {
    GetAllOrganization(token)
      .then((data) => setAllOrganization(data.result))
      .catch((err) => console.log(err));
  }, []);

  const TableHeading = [
    "S.No.",
    "Bank Name",
    "Type of Branch",
    "Branch Code / IFSC",
    "Email Id",
    "Manager Name",
    "Location",
    "Branch Location",
    "Status",
    "Contact No.",
  ];
  const CyberTableHeading = [
    "S.No.",
    "Cyber Cell Name",
    "Cyber Cell Branch",
    "Cyber Cell Location",
    "Cyber Cell Code",
    "Email",
    "Status",
    "Type",
  ];
  const PaymentTableHeading = [
    "S.No.",
    "Payment Gateway Name",
    "Location",
    "Contact No",
    "Email",
    "Payment Gateway Auth Person",
    "Status",
    "Licence No",
  ];
  const MerchantTableHeading = [
    "S.No.",
    "Merchant Name",
    "Email Id",
    "MID",
    "Merchant VPZ",
    "Merchant Address",
    "City",
    "State",
    "Pincode",
    "Country",
    "Status",
    "URL Website",
  ];
  return (
    <div className="bg-[#FAFBFF] overflow-x-hidden">
      <DashboardHeader />
      {/* Dashboard header end */}

      {/* pagination start */}

      <div className="flex justify-between items-center px-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex gap-4 items-center">
            <div
              onClick={() => {
                setTabs(0);
              }}
              className={
                tabs == 0
                  ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                  : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
              }
            >
              <span
                className={
                  tabs == 0
                    ? "text-sm font-poppins text-[#3F85EC] font-medium"
                    : "text-sm font-poppins text-[#85868A]"
                }
              >
                Bank
              </span>
            </div>
            <div
              onClick={() => {
                setTabs(1);
              }}
              className={
                tabs == 1
                  ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                  : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
              }
            >
              <span
                className={
                  tabs == 1
                    ? "text-sm font-poppins text-[#3F85EC] font-medium"
                    : "text-sm font-poppins text-[#85868A]"
                }
              >
                Cyber Cell / Police
              </span>
            </div>

            <div
              onClick={() => {
                setTabs(2);
              }}
              className={
                tabs == 2
                  ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                  : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
              }
            >
              <span
                className={
                  tabs == 2
                    ? "text-sm font-poppins text-[#3F85EC] font-medium"
                    : "text-sm font-poppins text-[#85868A]"
                }
              >
                Payment Gateway
              </span>
            </div>

            <div
              onClick={() => {
                setTabs(3);
              }}
              className={
                tabs == 3
                  ? "py-1 px-2 bg-[#EFF3F9] border border-[#dfdfdf] rounded-md cursor-pointer"
                  : "py-1 px-2 bg-[#fff] border border-[#dfdfdf] rounded-md cursor-pointer"
              }
            >
              <span
                className={
                  tabs == 3
                    ? "text-sm font-poppins text-[#3F85EC] font-medium"
                    : "text-sm font-poppins text-[#85868A]"
                }
              >
                Merchant
              </span>
            </div>
          </div>


          <div
            onClick={() => {
              navigate("/superadmin/add-new-user", {
                state: {
                  tab: tabs,
                },
              });
            }}
          >
            <button className="bg-gradient-to-r from-[#3C76D5] to-[#0AB9FC] px-4 py-2 rounded-full text-[#fff]">
              Add Organization
            </button>
          </div>
        </div>
      </div>
      {/* pagination end */}
      <div className="px-4">
        <div className="bg-[#fff]">
          {tabs == 0 && (
            <BankTable
              TableHeading={TableHeading}
              sampleData={allOrganization?.bank}
            />
          )}
          {tabs == 1 && (
            <CyberTable
              TableHeading={CyberTableHeading}
              sampleData={allOrganization?.cybercell}
            />
          )}
          {tabs == 2 && (
            <PaymentGatewayTable
              TableHeading={PaymentTableHeading}
              sampleData={allOrganization?.payment}
            />
          )}
          {tabs == 3 && (
            <MerchantTable
              TableHeading={MerchantTableHeading}
              sampleData={allOrganization?.merchant}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminOrgManagement;
